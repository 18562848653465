import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { UserService } from '../user/user.service'
import { LoginPayload, ResetPasswordPayload, ResetPasswordRequestPayload, StorageKeys } from '../../models/user.model'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  meditlinkCallBack = new Subject<any>();
  meditlinkCallBackObservable$ = this.meditlinkCallBack.asObservable()

  constructor(private http: HttpClient, private router: Router, private userService: UserService) {}

  isAuthenticated() {
    const token = this.getToken()
    return !!token
  }

  setToken(token: string) {
    localStorage.setItem(StorageKeys.AccessToken, token)
  }

  getToken(): string {
    let token = localStorage.getItem(StorageKeys.AccessToken)
    if (!token) {
      token = sessionStorage.getItem(StorageKeys.AccessToken)
    }
    return token || ''
  }

  login(data: LoginPayload) {
    return this.http.post('/api/clinic/login', data).toPromise()
  }
 
  logout() {
    localStorage.clear()
    this.router.navigate(['auth/login'])
  }

  sendResetPassword(payload: ResetPasswordPayload) {
    return this.http.post('/api/password/reset', payload).toPromise()
  }

  sendResetPasswordRequest(payload: ResetPasswordRequestPayload) {
    return this.http.post('/api/password/reset/send', payload).toPromise()
  }

  setMeditUser(user: any){
    console.log('user == ', user)
    this.meditlinkCallBack.next(user)
  }
}
